.Loader, .Loader--isTimedOut, .Loader--isError {
  font-size: 1.2rem;
  text-align: center;
  font-family: "Avenir Next";
  font-weight: 500; }

.Loader--isTimedOut {
  color: #505050; }

.Loader--isError {
  color: #F44336; }

@media screen and (min-width: 768px) {
  .Loader, .Loader--isTimedOut, .Loader--isError {
    margin-top: 147px;
    font-size: 1.5rem; } }
